import React from 'react';
import Message from './message';


const MessageList = ({ messages, isLoading, error }) => {
    // if (isLoading) {
    //   return <div className="card"><div className="card-body">Loading messages...</div></div>;
    // }
  
    if (error) {
      return <div className="card"><div className="card-body text-danger">Error: {error}</div></div>;
    }
  
    return (
      <div className="card">
        <div className="card-header">Messages</div>
        <div className="card-body p-2" style={{maxHeight: '85vh', overflowY: 'auto'}}>
          {messages && messages.map((message) => (
            <Message key={message.id} message={message}/>
          ))}
        </div>
      </div>
    );
  };
 
export default MessageList;