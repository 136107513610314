import React, { useState } from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Modal from 'react-bootstrap/Modal';

const Message = ({ message }) => {
  const [showModal, setShowModal] = useState(false);

  const extractLocalTime = (utcTimeString) => {
    const date = new Date(utcTimeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="card mb-2">
      <div className="card-body py-2 px-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="card-title mb-0 fw-bold">{message.chatTitle}</h6>
          <small className="text-muted">{extractLocalTime(message.createdAt)}</small>
        </div>
        <p className="mb-1 small">
          <Markdown remarkPlugins={[remarkGfm]}>{message.message}</Markdown>
        </p>
        {message.imageUrl && (
          <div className="mt-2 mb-2">
            <img
              src={message.imageUrl}
              alt="Message attachment"
              className="img-thumbnail"
              style={{ maxWidth: '100px', cursor: 'pointer' }}
              onClick={handleImageClick}
            />
          </div>
        )}
        <div className="mt-1">
          {message.telegramLink && (
            <a href={message.telegramLink} className="btn btn-sm btn-light me-1" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-telegram"></i>
            </a>
          )}
          {message.twitterLink && (
            <a href={message.twitterLink} className="btn btn-sm btn-light me-1" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-twitter"></i>
            </a>
          )}
          {message.website && (
            <a href={message.website} className="btn btn-sm btn-light me-1" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-globe"></i>
            </a>
          )}
          {message.chartLink && message.chartLink.map(link => (
            <a href={link} className="btn btn-sm btn-light me-1" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-line-chart"></i>
            </a>
          ))}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={message.imageUrl}
            alt="Message attachment"
            style={{ width: '100%', height: 'auto' }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Message;