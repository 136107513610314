import React, { useState, useEffect, useCallback } from 'react';
import MessageList from "./message-list";
import TrendingList from "./trending-list";
import Navbar from "./navbar";

const Dashboard = () => {
    const [messages, setMessages] = useState([]);
    const [trendingData, setTrendingData] = useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState('15m');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pollingInterval, setPollingInterval] = useState(30000); // 30 seconds default

    const fetchMessages = useCallback(async () => {
        try {
            setIsLoading(true);
            let response ; 
            if(process.env.NODE_ENV === 'development')
                response = await fetch('http://localhost:1120/api/messages?page=1&limit=50');
            else response = await fetch('/api/messages?page=1&limit=500');
            
            if (response.status === 304) {
                console.log('Data not modified since last fetch');
                return;
            }
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // console.log(data.messages[0])
            if (!Array.isArray(data.messages)) {
                throw new Error('Received data is not an array');
            }
            setMessages(data.messages);
            setError(null);
        } catch (e) {
            console.error("An error occurred while fetching the messages:", e);
            setError(e.message || "Failed to fetch messages. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchTrending = useCallback(async (timeframe) => {
        try {
            setIsLoading(true);
            let response;
            if(process.env.NODE_ENV === 'development')
                response = await fetch(`http://localhost:1120/api/trending/${timeframe}?limit=15&tickersOnly=false`);
            else response = await fetch(`/api/trending/${timeframe}?limit=15&tickersOnly=false`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setTrendingData(data);
            setError(null);
        } catch (e) {
            console.error(`An error occurred while fetching trending data for ${timeframe}:`, e);
            setError(e.message || `Failed to fetch trending data for ${timeframe}. Please try again later.`);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await fetchMessages();
            await fetchTrending(selectedTimeframe);
        };

        fetchData(); // Initial fetch

        const intervalId = setInterval(fetchData, pollingInterval);
        return () => clearInterval(intervalId);
    }, [fetchMessages, fetchTrending, selectedTimeframe, pollingInterval]);

    const handleTimeframeChange = (timeframe) => {
        setSelectedTimeframe(timeframe);
    };

    const handleRefresh = () => {
        fetchMessages();
        fetchTrending(selectedTimeframe);
    };

    const handleIntervalChange = (event) => {
        const newInterval = parseInt(event.target.value, 10);
        setPollingInterval(newInterval);
    };

    return (
        <div className="d-flex flex-column vh-100">
            <Navbar />
            <div className="container-fluid flex-grow-1 mt-3">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <button onClick={handleRefresh} className="btn btn-primary me-2">
                            Refresh Now
                        </button>
                        <select 
                            value={pollingInterval} 
                            onChange={handleIntervalChange}
                            className="form-select d-inline-block w-auto"
                        >
                            <option value={5000}>Every 5 seconds</option>
                            <option value={10000}>Every 10 seconds</option>
                            <option value={30000}>Every 30 seconds</option>
                            <option value={60000}>Every 1 minute</option>
                        </select>
                    </div>
                </div>
                <div className="row h-100">
                    <div className="col-md-3">
                        
                    </div>
                    <div className="col-md-7">
                        <MessageList messages={messages} isLoading={isLoading} error={error} />
                    </div>
                    <div className="col-md-2">
                        <div className="btn-group mb-3 d-flex" role="group" aria-label="Trending timeframe">
                            <button type="button" className={`btn btn-outline-primary ${selectedTimeframe === '15m' ? 'active' : ''}`} onClick={() => handleTimeframeChange('15m')}>15m</button>
                            <button type="button" className={`btn btn-outline-primary ${selectedTimeframe === '1h' ? 'active' : ''}`} onClick={() => handleTimeframeChange('1h')}>1h</button>
                            <button type="button" className={`btn btn-outline-primary ${selectedTimeframe === '6h' ? 'active' : ''}`} onClick={() => handleTimeframeChange('6h')}>6h</button>
                            <button type="button" className={`btn btn-outline-primary ${selectedTimeframe === '1d' ? 'active' : ''}`} onClick={() => handleTimeframeChange('1d')}>1d</button>
                        </div>
                        <TrendingList title={`Trending (${selectedTimeframe})`} trendingData={trendingData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;