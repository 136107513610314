import React from 'react';
import Trending from './trending';

const TrendingList = ({ title, trendingData }) => (
    <div className="card">
      <div className="card-header">{title}</div>
      <ul className="list-group list-group-flush" style={{maxHeight: '70vh', overflowY: 'auto'}}>
        {trendingData.map((data, index) => (
          <Trending key={index} ticker={data.word} value={data.count} />
        ))}
      </ul>
    </div>
  );

export default TrendingList;